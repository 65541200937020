<template>
  <div>
    <Row :gutter="8" class="p-b-10">
    <i-col :xs="24" :sm="12" :md="4" :lg="4"  class="m-b-5"  v-if="currentUserType===1">
        <Select v-model="demandCompanyId" >
          <Option v-for="(demandCompany,index) in demandCompanies" :key="index" :value="demandCompany.id">{{demandCompany.name}}</Option>
        </Select>
      </i-col>
      <i-col :xs="24" :sm="12" :md="4" :lg="4"  class="m-b-5">
      <DatePicker
        type="year"
        v-model="year"
        placeholder="选择年份"
        style="width: 200px"
        :clearable="false"
        :transfer="true"
      ></DatePicker>
       </i-col>
    </Row>
    <Table
      border stripe
      :loading="dataLoading"
      :data="list"
      :columns="dataColumns"
      show-summary
      :summary-method="handleSummary"
    ></Table>
  </div>
</template>

<script>
import { getAgentBillList } from '@/api/statement/agentbill'
import { getDispatcherList } from '@/api/os/company'
import { toMoney } from '@/utils/wnumb_own'
import { formatAgentStatement } from '@/utils/tagStatus'
export default {
  data () {
    return {
      year: new Date().getFullYear().toString(),

      currentUserType: 1, // 1 表示业主，2表示派单公司，3表示供应商
      demandCompanies: [], // 派单公司
      demandCompanyId: 0,

      publisherId: this.$store.getters.token.userInfo.publisherId,
      agentArray: [],
      dataLoading: false,
      list: [],
      dataColumns: [
        {
          title: '概况',
          align: 'center',
          children: [
            { title: '季度', align: 'center', key: 'name' },
            {
              title: '季度指标',
              align: 'center',
              // width: 160,
              key: 'planAmount',
              render: (h, param) => {
                return h('span', toMoney(param.row.planAmount))
              }
            },
            {
              title: '权益额',
              align: 'center',
              // width: 160,
              key: 'realAmount',
              render: (h, param) => {
                return h('span', toMoney(param.row.executedAmount))
              }
            },
            {
              title: '完成比',
              align: 'center',
              // width: 100,
              key: 'finishRatio',
              render: (h, param) => {
                return h('span', `${(param.row.finishRatio * 100).toFixed(2)} %`)
              }
            }
          ]
        },
        {
          title: '权益额明细',
          align: 'center',
          children: [
            {
              title: '发布费',
              align: 'center',
              // width: 160,
              key: 'publishAmount',
              render: (h, param) => {
                return h('span', toMoney(param.row.publishAmount))
              }
            },
            {
              title: '上下刊费',
              align: 'center',
              // width: 160,
              key: 'installAmount',
              render: (h, param) => {
                return h('span', toMoney(param.row.installAmount))
              }
            },
            {
              title: '制作费',
              align: 'center',
              // width: 160,
              key: 'produceAmount',
              render: (h, param) => {
                return h('span', toMoney(param.row.produceAmount))
              }
            }
          ]
        },
        {
          title: '状态',
          align: 'center',
          fixed: 'right',
          width: 120,
          render: (h, param) => {
            return formatAgentStatement(h, param.row.status, param.row.statusName)
          }
        },
        {
          title: '操作',
          align: 'center',
          fixed: 'right',
          width: 160,
          render: (h, param) => {
            const innerHtml = [
              h('a', {
                props: { marginRight: '5px' },
                on: {
                  click: () => {
                    this.$store.commit('set_reconciliation_pageType', 'detail')
                    this.$store.commit('set_reconciliation_bill', param.row)
                  }
                }
              }, '详情')
            ]
            if (param.row.status === 1) {
              innerHtml.push(
                h('a', {

                }, '')
              )
            }
            return h('div', innerHtml)
          }
        }
      ]
    }
  },
  created () {
    this.initComponentFileters()
  },
  methods: {
    // 初始化用户类型、筛选条件
    initComponentFileters () {
      const that = this
      getDispatcherList({ companyId: that.publisherId }).then(res => {
        if (res.length) {
          if (res.filter(item => item.id === that.companyId).length) { // 判断当前用户是否是派单公司
            that.currentUserType = 2
            that.demandCompanyId = that.companyId
          } else if (that.companyId === that.publisherId) { // 判断当前用户是否是业主
            that.currentUserType = 1
            that.demandCompanies = res
            that.demandCompanyId = res[0].id
          } else { // 判断用户为供应商
            that.currentUserType = 3
          }
        }
      })
    },

    handleSummary ({ columns, data }) {
      const sums = {}
      columns.forEach((column, index) => {
        const key = column.key
        if (index === 0) {
          sums[key] = {
            key,
            value: '总计'
          }
        } else if (index === 3 || index >= 11) {
          sums[key] = {
            key,
            value: '-'
          }
        } else {
          const values = data.map(item => Number(item[key]))
          if (!values.every(value => isNaN(value))) {
            const v = values.reduce((prev, curr) => {
              const value = Number(curr)
              if (!isNaN(value)) {
                return prev + curr
              } else {
                return prev
              }
            }, 0)
            sums[key] = {
              key,
              value: toMoney(v)
            }
          } else {
            sums[key] = {
              key,
              value: '-'
            }
          }
        }
      })

      return sums
    },
    initData () {
      this.dataLoading = true
      const postData = {
        companyId: this.demandCompanyId,
        publisherId: this.publisherId,
        year: this.year
      }
      getAgentBillList(postData).then(res => {
        this.dataLoading = false
        if (res && !res.errcode) {
          this.list = res
        } else {
          this.list = []
          this.$Notice.error({ title: '获取代理商对账簿出错', desc: res.errmsg })
        }
      }).catch(() => {
        this.dataLoading = false
      })
    },
    handleSearch () {
      this.year = new Date(this.year).getFullYear().toString()
      this.initData()
    }
  },
  computed: {
    beginUpdate () {
      return this.$store.state.reconciliation.beginUpdate
    },
    companyId () {
      return this.$store.state.reconciliation.companyId
    }
  },

  watch: {
    year (val) {
      if (this.companyId) {
        this.handleSearch()
      }
    },
    demandCompanyId (val) {
      this.demandCompanyId = val
      this.handleSearch()
    }
  }
}
</script>
